//	Dependencies
import React, { useEffect, useRef } from 'react';
import _debounce from 'lodash-es/debounce';
import clsx from 'clsx';

//	App
import { useStore } from 'base/state';
import { Footer, Header, Menu } from 'ui/app';
import { useIsMobile } from 'src/hooks';
import { NoSSR } from 'ui/components';
import { LoadingView } from 'ui/views';
import useScrollSpring, { ScrollSpringContextProvider } from 'src/hooks/use-scroll-spring';

// Styles
import styles from './layout.module.scss';
import ToolTip from 'src/ui/components/tooltip/tooltip';
import { Modal } from 'src/ui/components';
import { RM } from '@request-metrics/browser-agent';


// TODO: further simply. threejs related code was already removed as of 31 Oct 2024

const Layout = React.memo(function _Layout({ className, children, footerVariation, includeInPage, navigationVariation, includeFooterInPage }) {
  const api = useStore(s => s.api);

  useEffect(() => {
    api.setIsLoaded(true);
  }, [api]);


  useEffect(() => {
    RM.install({
      token: 'y9km6jb:i9zf8nu',
    });
  }, []);

  return (
    <div className={clsx('a-layout', styles['a-layout'], className)}>
      <ScrollSpringContextProvider>
        <Main>
          <ToolTip pushDown={includeInPage} />
          <Modal />
          <Header includeInPage={includeInPage}
            variation={navigationVariation} />
          <Menu onClose={() => api.closeMenuView()}
            variation={navigationVariation} />
          {children && children}
          <Footer variation={footerVariation}
            includeInPage={includeInPage || includeFooterInPage} />
        </Main>
      </ScrollSpringContextProvider>
      <NoSSR>
        <LoadingView />
        <BodyHeightSentinel />
      </NoSSR>
    </div>
  );
});

export default Layout;

function BodyHeightSentinel() {
  const bodyHeight = useStore(s => s.bodyHeight);

  useEffect(() => {
    const isForcedHeight = !(typeof bodyHeight !== 'number');
    const elHeight = isForcedHeight ? bodyHeight : 'auto';
    document.body.style.height = `${elHeight}px`;
  }, [bodyHeight]);
}

const Main = React.memo(function _Main({ children, className }) {
  const api = useStore(s => s.api);
  const isMobile = useIsMobile();
  const cachedViewportHeight = useRef(0);
  const [, { setScrollNode, setMainNode, mainNode, scrollNode }] = useScrollSpring();

  useEffect(() => {
    const _resizeHandler = _debounce(() => {
      const _wW = window?.innerWidth;
      const _wH = scrollNode?.offsetHeight;
      const _bH = mainNode?.scrollHeight;

      if (typeof _wW !== 'number' || typeof _wH !== 'number' || typeof _bH !== 'number') return;
      if (isMobile && cachedViewportHeight.current === _wH) return;
      const viewport = [_wW, _wH];
      api.setViewport(viewport, _bH);
      cachedViewportHeight.current = _wH;
    }, 100);

    _resizeHandler();

    window.addEventListener('resize', _resizeHandler, { passive: true });
    return () => window.removeEventListener('resize', _resizeHandler);
  }, [api, mainNode, scrollNode, isMobile]);

  return (
    <div className={clsx('scroll-container', '-is-loaded')}
      ref={$el => setScrollNode($el)}>
      <main
        id="main-content"
        className={clsx(styles['a-layout_main'], className)}
        ref={el => {
          if (!el) return;
          setMainNode(el);
          api.setViewport(null, el.clientHeight);
        }}
      >
        {children && children}
      </main>
    </div>
  );
});
